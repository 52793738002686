<template>
  <div>
    <KTCodePreview v-bind:title="'Nhóm tồn ảo sản phẩm'">
      <template v-slot:preview>
        <b-row>
          <b-col md="3">
            <b-form-group class="required-control">
              <label> Tên nhóm: </label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                placeholder="Nhập tên nhóm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="form-group-label">Trạng thái:</label>
              <b-form-select
                size="sm"
                v-model="mainModel.isActive"
                :options="listStatus"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label class="form-group-label"
                >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ thống):</label
              >
              <vue-multi-select
                v-model="mainModel.storeIds"
                :options="stores"
                placeholder="Chọn cửa hàng"
                option-label="name"
              ></vue-multi-select>
            </b-form-group>
          </b-col>
        </b-row>
        <label class="form-group-label">Tìm sản phẩm:</label>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <vue-autosuggest
                v-model="searchProductText"
                :suggestions="filteredOptions"
                :limit="10"
                @selected="onSelectedProduct"
                @input="onInputChangeProduct"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Chọn sản phẩm',
                }"
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  {{ suggestion.item.productName }}
                </div>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="2" class="p-0">
            <b-form-select
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
              @change="searchProductAPI(searchProductText)"
            ></b-form-select>
          </b-col>
          <b-col md="2" class="p-0"> </b-col>
          <b-col md="2">
            <div class="d-flex justify-content-end">
              <b-button
                variant="secondary"
                size="sm"
                class="font-weight-bolder"
                @click="showModal()"
              >
                <i style="font-size: 1rem" class="far fa-file-excel"></i>Nhập SP
                từ excel
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-table
          :fields="fieldProduct"
          :items="listItem"
          class="table-bordered table-hover col-md-6"
        >
          <template v-slot:cell(actions)="row">
            <v-icon
              small
              class="text-danger text-center"
              @click="showDeleteAlert(row.item.productId)"
              v-b-tooltip
              title="Xóa"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
        <b-button
          style="fontweight: 600; width: 70px"
          class="mr-4"
          variant="primary"
          size="sm"
          v-show="
            checkPermission('VIRTUAL_STOCK_INSERT') ||
            checkPermission('VIRTUAL_STOCK_UPDATE')
          "
          @click="onSubmit()"
          >Lưu</b-button
        >
        <b-button
          style="font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="onBack"
          >Hủy</b-button
        >
        <b-modal
          ref="import-product-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Tiến hành Import </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFile"
                  v-model="file"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItems.length }} sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImport"
              :items="importItems"
              :per-page="10"
              :current-page="currentPage"
            >
              <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 1">Sản phẩm</span>
                <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
              </template>

              <template v-slot:cell(sellingPrice)="row">
                <div style="text-align: end">
                  <span v-text="formatMoney(row.item.sellingPrice)"></span>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="importItems.length"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validData"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModal"
            >Hủy</b-button
          >
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_VIRTUAL_STOCK,
} from '../../../utils/constants';
import decounce from 'debounce';
import VueMultiSelect from 'vue-simple-multi-select';
import _ from 'lodash';
import xlsx from 'xlsx';

export default {
  data() {
    return {
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchProductText: '',
      selectedId: null,
      mainModel: {
        name: '',
        listProduct: [],
        storeIds: [],
        isActive: 1,
      },
      stores: [],
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 0,
          name: 'Khóa',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm',
        },
        {
          id: 2,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 3,
          name: 'Sản phẩm combo',
        },
      ],
      selectTypeSearch: 1,
      fieldProduct: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
        },
      ],
      listItem: [],
      id: null,
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      currentPage: 1,
      importItems: [],
      file: null,
      excellist: [],
      urlExcel: URL_IMPORT_PRODUCT_VIRTUAL_STOCK,
    };
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình tồn ảo sản phẩm' },
    ]);
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getById();
    }
  },
  created() {
    this.fetchStore();
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showDeleteAlert: function (productId) {
      Swal.fire({
        title: 'Xóa sản phẩm trong nhóm tồn ảo sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm trong nhóm tồn ảo sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItemInArray(productId);
        }
      });
    },
    onSelectedProduct(option) {
      this.validProduct(option.item.productId);
      this.searchProductText = '';
    },
    onInputChangeProduct(text) {
      this.searchProductText = text;

      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.searchProductAPI(this.searchProductText);
    }, TIME_TRIGGER),
    searchProductAPI: async function (textSearch) {
      //  init params
      const param = {
        searchText: textSearch,
        productType: this.selectTypeSearch,
      };

      const paramQuery = {
        params: param,
      };

      ApiService.query('virtual-stocks/search-product', paramQuery).then(
        (response) => {
          this.options[0].data = [];
          response.data.data.forEach((item) => {
            const product = {
              productId: item.id,
              productCode: item.productCode,
              productName: item.productName,
            };

            this.options[0].data.push(product);
          });
          this.filteredOptions = [
            {
              data: this.options[0].data,
            },
          ];
        }
      );
    },
    fetchStore: async function () {
      this.stores = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.stores = stores;
      });
    },
    onSubmit() {
      if (this.mainModel.name.trim() === '') {
        this.makeToastFaile('Vui lòng nhập tên nhóm sản phẩm tồn ảo!');
        return;
      }

      const listProductId = _.map(this.listItem, 'productId');
      if (listProductId.length === 0) {
        this.makeToastFaile('Vui lòng chọn sản phẩm!');
        return;
      }

      this.mainModel.listProduct = listProductId;

      if (!this.id) {
        this.onCreate();
      } else {
        this.onUpdate();
      }
    },
    getById: async function () {
      this.storeIds = [];
      await ApiService.get('virtual-stocks/' + this.id).then((res) => {
        const { name, listStore, isActive, listProduct } = res.data.data;
        this.listItem = listProduct;
        this.mainModel.name = name;
        this.mainModel.isActive = isActive;
        this.mainModel.storeIds = listStore.map((x) => x);
      });
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.listItem.length; i++) {
        if (this.listItem[i].productId === productId) {
          this.listItem.splice(i, 1);
        }
      }
    },
    onCreate() {
      let storeIds = '-1';
      if (this.mainModel.storeIds.length > 0) {
        storeIds = _.map(this.mainModel.storeIds, 'id').join();
      }

      let data = Object.assign({}, this.mainModel);
      data.storeIds = storeIds;

      ApiService.post('virtual-stocks', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            this.onBack();
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
        });
    },
    onUpdate() {
      let storeIds = '-1';
      if (this.mainModel.storeIds.length > 0) {
        storeIds = _.map(this.mainModel.storeIds, 'id').join();
      }

      let data = Object.assign({}, this.mainModel);
      data.storeIds = storeIds;
      data.id = this.id;

      ApiService.put('virtual-stocks', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            this.onBack();
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
        });
    },
    onBack() {
      this.$router.push({
        name: 'list-virtual-stock',
      });
    },
    validProduct(productId) {
      const data = {
        productId: productId,
        virtualStockId: this.id !== undefined ? this.id : null,
      };

      ApiService.post('virtual-stocks/valid-existed-product', data)
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === 1) {
            this.listItem.push(data);
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
        });
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          });
          const excellist = [];

          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              productName: element['Tên sản phẩm'],
              productCode: element['Mã sản phẩm'],
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModal() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModal() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      const data = {
        listData: this.importItems,
        virtualStockId: this.id !== undefined ? this.id : null,
      };

      ApiService.post('virtual-stocks/valid-import-product', data)
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === 1) {
            this.listItem = this.listItem.concat(data);
            this.hideModal();
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
        });
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.form-group-label {
  font-weight: 500;
}
</style>